import React from 'react';
import {
  faDownload,
  faRobot,
  faTable,
  faUserHeadset,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { ITotalMessageFilterNumber } from '@types';
import { IDropDownData } from '@amityco/diana-bot';
import dayjs from 'dayjs';

export const TOTAL_MESSAGE_CHART_ACTION_LIST = [
  {
    label: (
      <FormattedMessage id="dashboard.total.message.chart.action.list.view.timestamp" />
    ),
    value: 'view_timestamp',
    icon: <FontAwesomeIcon icon={faTable} fontSize={16} color="#757575" />,
  },
  {
    label: (
      <FormattedMessage id="dashboard.total.message.chart.action.list.download.csv" />
    ),
    value: 'download_csv',
    icon: <FontAwesomeIcon icon={faDownload} fontSize={16} color="#757575" />,
  },
];

export const enum EFilterInterval {
  HOURLY = '1',
  DAILY = 'daily',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export const enum EChartEvent {
  DID_CREATE = 'bot.channel.didCreate',
  DID_DELETE = 'bot.channel.didDelete',
  DID_UPDATE = 'bot.channel.didUpdate',
}

export const FILTER_INTERVAL = [
  {
    label: 'Daily',
    value: EFilterInterval.DAILY,
  },
  {
    label: 'Monthly',
    value: EFilterInterval.MONTHLY,
  },
  {
    label: 'Quarterly',
    value: EFilterInterval.QUARTERLY,
  },
  {
    label: 'Yearly',
    value: EFilterInterval.YEARLY,
  },
];

export const enum EDateInterval {
  LAST_24_H = 'L24H',
  TODAY = 'TODAY',
  LAST_7_D = 'L7D',
  LAST_30_D = 'L30D',
  LAST_3_M = 'LM',
  LAST_6_M = 'L6M',
  LAST_12_M = 'L12M',
  CUSTOM = 'CUSTOM',
  LAST_2_D = 'L2D',
}

export const DATE_INTERVAL = [
  {
    label: 'Custom',
    value: EDateInterval.CUSTOM,
  },
  {
    label: 'Last 24H',
    value: EDateInterval.LAST_24_H,
  },
  {
    label: '7D',
    value: EDateInterval.LAST_7_D,
  },
  {
    label: '30D',
    value: EDateInterval.LAST_30_D,
  },
  {
    label: '3M',
    value: EDateInterval.LAST_3_M,
  },
  {
    label: '6M',
    value: EDateInterval.LAST_6_M,
  },
  {
    label: '12M',
    value: EDateInterval.LAST_12_M,
  },
];

export const DATE_INTERVAL_JOURNEY = [
  {
    label: 'Custom',
    value: EDateInterval.CUSTOM,
  },
  {
    label: 'Last 2D',
    value: EDateInterval.LAST_2_D,
  },
  {
    label: '7D',
    value: EDateInterval.LAST_7_D,
  },
  {
    label: '30D',
    value: EDateInterval.LAST_30_D,
  },
  {
    label: '3M',
    value: EDateInterval.LAST_3_M,
  },
  {
    label: '6M',
    value: EDateInterval.LAST_6_M,
  },
  {
    label: '12M',
    value: EDateInterval.LAST_12_M,
  },
];

export const MIN_PAST_DATE = dayjs().subtract(6, 'month');
export const DATE_USER_JOURNEY_INTERVAL = [
  {
    label: 'Custom',
    value: EDateInterval.CUSTOM,
  },
  {
    label: 'Last 24H',
    value: EDateInterval.LAST_24_H,
  },
  {
    label: '7D',
    value: EDateInterval.LAST_7_D,
  },
  {
    label: '30D',
    value: EDateInterval.LAST_30_D,
  },
  {
    label: '3M',
    value: EDateInterval.LAST_3_M,
  },
  {
    label: '6M',
    value: EDateInterval.LAST_6_M,
  },
];

const iconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 4,
  background: '#E5E5E5',
  borderRadius: 10,
  height: 16,
  columnGap: 4,
};

export const textStyle = {
  fontSize: 10,
  fontWeight: 600,
  lineHeight: '12px',
  color: '#757575',
}; // cannot use Font10G4W600 cause test error

export const TOTAL_MESSAGE_NUMBER_FILTER: ITotalMessageFilterNumber[] = [
  {
    label: 'User',
    value: 'user',
    icon: (
      <div style={iconStyle}>
        <FontAwesomeIcon icon={faUsers} width={12} height={9} />
      </div>
    ),
  },
  {
    label: 'Bot',
    value: 'bot',
    icon: (
      <div style={iconStyle}>
        <FontAwesomeIcon icon={faRobot} width={12} height={9} />
      </div>
    ),
  },
  {
    label: 'Agent',
    value: 'agent',
    icon: (
      <div style={iconStyle}>
        <FontAwesomeIcon icon={faUserHeadset} width={12} height={9} />
      </div>
    ),
  },
];

export const TOTAL_MESSAGE_NUMBER_FILTER_CHART: ITotalMessageFilterNumber[] = [
  {
    label: 'User',
    value: 'user',
    icon: (
      <div style={iconStyle}>
        <FontAwesomeIcon icon={faUsers} width={12} height={9} />{' '}
        <span style={textStyle}> USER</span>
      </div>
    ),
  },
  {
    label: 'Bot',
    value: 'bot',
    icon: (
      <div style={iconStyle}>
        <FontAwesomeIcon icon={faRobot} width={12} height={9} />{' '}
        <span style={textStyle}> BOT</span>
      </div>
    ),
  },
  {
    label: 'Agent',
    value: 'agent',
    icon: (
      <div style={iconStyle}>
        <FontAwesomeIcon icon={faUserHeadset} width={12} height={9} />{' '}
        <span style={textStyle}> AGENT</span>
      </div>
    ),
  },
];

// User Journey
export const SOURCE_DROPDOWN: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Template',
        value: 'template',
      },
      // TODO: will be added later
      // {
      //   label: 'Mapper',
      //   value: 'mapper',
      // },
      {
        label: 'Rich Menu',
        value: 'richmenu',
      },
    ],
  },
];
